.react-code-input {
    display: inline-block;
    
}

.react-code-input>input {
    font-family: monospace;
    border-radius: 6px;
    border: 1px solid lightgrey;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
    margin: 4px;
    padding-left: 8px;
    width: 42px;
    height: 48px;
    font-size: 32px;
    box-sizing: border-box;
    color: black;
    background-color: white;
}

.react-code-input>input::selection {
    background-color: white;
}

.react-code-input>input::-webkit-outer-spin-button,
.react-code-input>input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.react-code-input>input[type=number] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}